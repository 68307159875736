.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}


h3 {
  margin-top: .45em;
  margin-bottom: .45em;
}