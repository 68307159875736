body {
  margin: 0;
  padding: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "PT Serif", serif;
}

#footer_site {
  background: #f4ede5;
  padding: 2.5rem 0;
  color: #49595e;
  font-size: .9em;
}

#logo.webheader, #logo a.webheader, #logo a.webheader:hover {
  font-weight: bold;
  text-transform: uppercase;
  color: #0c234b;
  font-size: 1.8em;
  text-decoration: none;
  display: flex;
  line-height: .9em;
  text-align: left;
}

@media (min-width: 768px) and (max-width: 1000px){

  .container {
    width: inherit;
}

}

.App {
  margin-top: 15px;
}

.App .navbar-brand {
  font-weight: bold;
}


h3 {
  margin-top: .45em;
  margin-bottom: .45em;
}
.NotFound {
  padding-top: 100px;
  text-align: center;
}

.Home .lander {
  padding: 80px 0;
  text-align: center;
}

.Home .lander h1 {
  font-weight: 500;
}

.Home .tables h1 {
  font-weight: 500;
}

span.text-size-h6 {
  font-weight: 400;
}

.Home .lander p {
  color: #999;
}

.expand-btn {
  padding-top: 0;
}

a.pos-info-link:hover, a.pos-info-link:focus {
  color: white;
}

.cursor {
	position: relative;
}
.cursor	i {
	position: absolute;
	width: 1px;
	height: 80%;
	background-color: gray;
	left: 5px;
	top: 10%;
	-webkit-animation-name: blink;
	        animation-name: blink;
	-webkit-animation-duration: 800ms;
	        animation-duration: 800ms;
	-webkit-animation-iteration-count: infinite;
	        animation-iteration-count: infinite;
	opacity: 1;
}

.cursor input:focus + i {
	display: none;
}

@-webkit-keyframes blink {
	from { opacity: 1; }
	to { opacity: 0; }
}

@keyframes blink {
	from { opacity: 1; }
	to { opacity: 0; }
}

input {
  caret-color: transparent;
}

input {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1) !important;
  padding: 3px 0px 3px 3px !important;
  margin: 5px 1px 3px 0px !important;
  border: 1px solid rgba(81, 203, 238, 1) !important;
}
 

